$(document).ready(function () {
    /*
    ██████╗  █████╗  ██████╗██╗  ██╗    ████████╗ ██████╗ ██████╗
    ██╔══██╗██╔══██╗██╔════╝██║ ██╔╝    ╚══██╔══╝██╔═══██╗██╔══██╗
    ██████╔╝███████║██║     █████╔╝        ██║   ██║   ██║██████╔╝
    ██╔══██╗██╔══██║██║     ██╔═██╗        ██║   ██║   ██║██╔═══╝
    ██████╔╝██║  ██║╚██████╗██║  ██╗       ██║   ╚██████╔╝██║
    ╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝       ╚═╝    ╚═════╝ ╚═╝
    */
    $("#backtotop").click(function () {
        $("html, body").animate(
            {
                scrollTop: "0px",
            },
            500
        );
    });

    //SCROLL
    $(window).scroll(function () {
        //BACK TO TOP
        if ($(this).scrollTop() > 400) {
            $("#backtotop").removeClass('opacity-0 invisible').addClass('opacity-100 visible');
        } else {
            $("#backtotop").removeClass('opacity-100 visible').addClass('opacity-0 invisible');
        }

        //HEADER
        if ($(this).scrollTop() > 0) {
            $('#header').addClass("scroll");
        } else {
            $('#header').removeClass("scroll");
        }
    });

    //ANIM SECTION SCROLL
    const sections = gsap.utils.toArray('section.section');
    sections.forEach((section, i) => {
        const anim = gsap.fromTo(section, {autoAlpha: 0, y: -50}, {duration: 1, autoAlpha: 1, y: 0});
        ScrollTrigger.create({
            start: "top 80%",
            trigger: section,
            animation: anim,
            once: true,
        });
    });

    // lightGallery //
    $(".lightview,.gallery").lightGallery({
        download: false,
        selector: 'a'
    }).on('onBeforeSlide.lg', function () {
        $('.lg-backdrop').css('opacity', '0.8');
        $('.lg-sub-html').css('opacity', '0')
    });

    /////////////////
    /// Parallax
    ////////////////
    if ($(window).width() > 1024) {
        $(".parallax-container").each(function () {
            var img = $(this).find("img");
            img.css("display", "none");
            $(this).parallax({
                imageSrc: img.attr("src"),
                zIndex: 0,
            });
        });
    }

    //HEADER
    $('#header .menu--event').click(function (){
        $('#header').toggleClass('menu--open');
        $('#menu-container').toggleClass('-translate-y-full');
    });
    $('#menu-header>.menu-item.menu-item-has-children').hover(function (){
       $(this).find('.sub-menu').stop().slideDown(300);
    }, function (){
        $(this).find('.sub-menu').stop().slideUp(300);
    });

    //MODAL
    $('.modal--event').click(function (e) {
        e.preventDefault();
        var content = $(this).find('.modal--content').html();
        openModal(content);
    });
    $('.modal--bg, #modal .close').click(function () {
        closeModal();
    });
    function openModal(content) {
        $('#modal').find('.container').append(content);
        $('.modal--container').addClass('show');
        $('html, body').addClass('overflow-hidden');
    }
    function closeModal() {
        $('.modal--container').removeClass('show');
        $('html, body').removeClass('overflow-hidden');
        setTimeout(function () {
            $('#modal').find('.container').empty();
        }, 300);
    }

    //FRONTPAGE CARD
    $('.header-frontpage-card').hover(
        function () {
            let background = $(this).closest('#page-header').find('.background-hover-effect');
            let color = $(this).data('color');
            background.css('background', color);
            background.removeClass('opacity-0');
        },
        function () {
            let background = $(this).closest('#page-header').find('.background-hover-effect');
            background.addClass('opacity-0');
        }
    );

    //FRONTPAGE NEXT SECTION
    $('body.home #next-section').click(function (){
        const section = $(this).closest('#page-header').next();
        $("html, body").animate(
            {
                scrollTop: section.offset().top-$('#header').height(),
            },
            500
        );
    });

    //SECTION EXPLICATIONS
    $('.explication-container').each(function (){
        const container = $(this);
        $(this).find('.cursor-pointer').click(function (){
           const hidden = container.find('.hidden');
           hidden.slideToggle(300);
           hidden.parent().toggleClass('bg-white box-shadow');
        });
    });

    //SECTION ACCORDEON
    $(".accordeon .accordeon--label").click(function () {
        const accordeon = $(this).parent();
        accordeon.toggleClass("accordeon--open");
        accordeon.find(">.accordeon--content").stop().slideToggle(300);
    });

    //SLIDER
    function sliderArticles(windowWidth){
        let slider = $('.articles-carousel');
        let options = {
            wrapAround: false,
            prevNextButtons: false,
            draggable: true,
        };

        if(windowWidth >= 1024 && windowWidth < 1280){
            options.cellAlign = "left";
        }

        if(slider.hasClass('flickity-enabled')) slider.flickity('destroy');
        if(windowWidth < 1280){
            let maxHeight = 0;
            slider.children().each(function (){
                let childHeight = $(this).height() + 24;
                if(!$(this).hasClass('h-full')){
                    $(this).addClass('h-full');
                }

                if (childHeight > maxHeight) {
                    maxHeight = childHeight;
                }
            });
            slider.flickity(options);
            slider.find('.flickity-viewport').height(maxHeight);
        }else{
            slider.children().each(function (){
                if($(this).hasClass('h-full')){
                    $(this).removeClass('h-full');
                }
            });
        }
    }
    $(window).resize(function (){
       sliderArticles($(window).width());
    });
    sliderArticles($(window).width());

    //TABS
    const tabs = $('#tabs');
    const contentTabs = $('#content-tabs');
    tabs.find('.tab').each(function (){
        $(this).click(function (){
            const tab = $(this).data('tab');
            const activeTab = tabs.find('.active');
            if(activeTab){
                $(activeTab).removeClass('active');
            }
            $(this).addClass('active');

            const content = contentTabs.find('.content-tab[data-tab="'+tab+'"]');
            const activeContent = contentTabs.find('.active');
            if(activeContent){
                activeContent.removeClass('active');
            }
            content.addClass('active');
        });
    });
    $("#content-tabs .tab-gallery.media").lightGallery({
        selector: 'a'
    }).on('onBeforeSlide.lg', function () {
        $('.lg-backdrop').css('opacity', '0.8');
    });
});